/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



html {
  scroll-behavior: smooth;
}


/* Rich text editor view */
/* Ensure that lists are styled properly */
.content-area ol, .content-area ul {
  margin-left: 20px;
  padding-left: 20px;
}

.content-area ol li {
  list-style-type: decimal; /* for numbered lists */
  margin-bottom: 10px;
}

.content-area ul li {
  list-style-type: disc; /* for bulleted lists */
  margin-bottom: 10px;
}

.content-area li {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}
